@import "../../node_modules/@implentio/ui-toolkit/dist/style.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --status-attention-text: 40 80% 34%;
    --status-attention-bg-default: 40 100% 88%;
    --status-success-bg-default: 110 46% 87%;
    --status-success-text: 112 46% 34%;
    --status-danger-bg-default: 3 82% 91%;
    --status-danger-text: 5 73% 39%;
    --status-neutral-bg-default: 249 58% 95%;
    --status-neutral-text: 249 59% 53%;

    --stroke-brand-emphasis: 249 59% 53%;
    --stroke-emphasis: 240 59% 15%;
    --stroke-brand-muted: 249 58% 81%;
    --stroke-brand-disabled: 240 59% 15%;
    --stroke-muted-disabled: 240 59% 15%;
    --stroke-focus: 249 59% 53%;
    --stroke-disabled: 240 59% 15%;
    --stroke-strong: 240 59% 15%;
    --stroke-validation-invalid-emphasis: 5 73% 39%;
    --stroke-validation-warning-emphasis: 40 80% 34%;
    --stroke-validation-success-emphasis: 112 46% 34%;

    --icons-default: 240 59% 15%;
    --icons-default-reverse: 0 0% 100%;
    --icons-disabled: 245 21% 77%;
    --icons-brand-disabled: 249 58% 81%;
    --icons-muted: 241 18% 46%;
    --icons-bg-brand-muted: 249 58% 81%;
    --icons-brand-emphasis: 249 59% 53%;
    --icons-danger: 5 73% 39%;
    --icons-success: 112 46% 34%;
    --icons-warning: 40 80% 34%;

    --bg-color-default: 0 0% 100%;
    --bg-color-brand-disabled: 249 58% 95%;
    --bg-color-disabled: 260 20% 97%;
    --bg-color-emphasis: 240 59% 15%;
    --bg-color-brand-emphasis: 249 59% 53%;
    --bg-color-brand-muted: 249 58% 81%;
    --bg-color-accent-emphasis: 22 92% 49%;
    --bg-color-brand-emphasis: 249 59% 53%;
    --bg-color-dark-brand-emphasis: 249 51% 43%;
    --bg-color-danger-muted: 3 82% 91%;
    --bg-color-success-emphasis: 112 46% 34%;
    --bg-color-success-muted: 110 46% 87%;
    --bg-color-muted: 241 18% 46%;
    --bg-color-warning-muted: 40 100% 88%;
    
    --shadow-disabled: 210 13% 12%;
    --inner-pressed-muted: 134 56% 18%;
    --inner-pressed-emphasis: 134 56% 18%;
    --inner-focus: 0 0% 100%;
    --shadow-muted: 210 13% 12%;
    
    --fg-color-default-reverse: 0 0% 100%;
    --fg-color-muted: 241 18% 46%;
    --fg-color-default: 240 59% 15%;
    --fg-color-brand-emphasis: 249 59% 53%;
    --fg-color-dark-brand-emphasis: 249 51% 43%;
    --fg-color-danger: 5 73% 39%;
    --fg-color-success: 112 46% 34%;
    --fg-color-disabled: 244 18% 66%;

    --info-message-danger-icon: 5 73% 39%;
    --info-message-warning-icon: 40 80% 34%;
    --info-message-success-icon: 112 46% 34%;

    --info-message-danger-text: 5 73% 39%;
    --info-message-warning-text: 40 80% 34%;
    --info-message-success-text: 112 46% 34%;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
}
}
